<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading dependants
  </loading-spinner>
  <div v-else>
    <template v-if="dashboardImages.length > 0">
      <img
        :src="Helper.formatMediaUrl(image)"
        class="mb-3 w-100 h-auto"
        @click="currentMedia = image"
        alt=""
        v-for="image in dashboardImages"
        :key="image._id"
      />
    </template>
    <div class="under-construction rounded" v-else></div>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      :mime-type="this.Helper.getMediaMimeType(currentMedia)"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import MediaViewer from "@/components/Modals/MediaViewer";

export default {
  components: {
    MediaViewer,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      dashboardImages: [],
      currentMedia: null,
    };
  },
  methods: {
    async getDependants() {
      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `dependants?_limit=-1&user=${this.$store.state.user._id}&status_in=Active&status_in=Inactive`
          );

          this.dashboardImages = data
            .filter((d) => d.dashboard_image)
            .map((d) => d.dashboard_image);
        } catch (error) {
          console.error(error);
        } finally {
          resolve();
        }
      });
    },
  },
  async mounted() {
    let waitUserAvailable = async () => {
      this.isLoading = true;

      if (this.$store.state.user) {
        await this.getDependants();

        this.isLoading = false;
      } else {
        setTimeout(() => {
          waitUserAvailable();
        }, 1000);
      }
    };

    waitUserAvailable();
  },
};
</script>